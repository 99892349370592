<template>
    <div
        class="row justify-content-center spacer-navigation"
        :class="{
            'spacer-footer': page?.body.length == 0,
        }"
    >
        <div class="col-12 col-640-8 spacer-180-140">
            <h1 class="scale-6 spacer-40">{{ page?.title }}</h1>
            <p class="equal-base">
                {{ page?.text }}
            </p>
        </div>
    </div>
    <template v-if="page?.body.length > 0">
        <hr class="spacer-100-80" />
        <div>
            <h2 class="scale-3 spacer-30">
                {{ t('thankYou.waiting') }}
            </h2>
            <div class="spacer-footer">
                <CmsBody :page="page" />
            </div>
        </div>
    </template>
</template>

<script setup>
import { useCheckoutRedirect } from '@/composables/useCheckoutRedirect';
import { useBasketStore } from '~/stores/basket';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const basketStore = useBasketStore();

onBeforeUnmount(() => {
    basketStore.updateBasket();
});

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

useCheckoutRedirect();

useHead({
    title: t('pages.title.thankYou'),
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

hr {
    color: #666666;
    opacity: 1;
    margin: 0;

    // spacer-100-80 !important
    margin-bottom: 100px !important;

    @include media-breakpoint-down(640) {
        margin-bottom: 80px !important;
    }
}
</style>
